import React, { useState } from "react"
import { Link } from "gatsby"
import { SERVICE_VIDEO } from "../../constants/images";

const JoinAccuIndex = ({
  trading_box = [],
  trading_description = {},
  trading_icon = {},
  trading_title = {},
  application_links = [],

  wide_choice = {},
  wide_range_description = {},
  wide_range_features = [],
  wide_range_thumbnail = {},

  next_level = {},
  next_level_button_clicked_image = {},
  next_level_button_image = {},
  next_level_description = {},
  next_level_thumbnail = {},
  next_level_risk = {},
  live_account_link = {},

}) => {
  const [featuresIcons, setFeaturesIcons] = useState(
    wide_range_features.map(feature => feature.icon.url)
  )

  const [nextLevelImage, setNextLevelImage] = useState(
    next_level_button_image.url
  )

  // const updateFeatureIcons = (index, icon) => {
  //   const icons = featuresIcons
  //   icons[index] = icon
  //   setFeaturesIcons(icons)
  // }
  return (
    <>
      <section className="content-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
                <div className="col-lg-7 order-lg-0 order-sm-1">
                  <div className="tradingPlatforms">
                    <div className="marTop40">
                      <h2>{trading_title[0] !== undefined && trading_title[0].text}</h2>
                      <p>{trading_description[0] !== undefined && trading_description[0].text}</p>
                      <ul>
                        {trading_box.map((obj, tradBoxInd) => (
                          <li
                            className="li-metaTrade "
                            key={"dfsdf" + tradBoxInd}
                          >
                            <a href={application_links[tradBoxInd] && application_links[tradBoxInd].link_url[0].text} target="_blank" rel="noreferrer">
                              <img
                                alt="ddd"
                                src={obj.trading_tile.url}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                      <div className="text-center applicationLinks">
                        {application_links.map((obj, appLinkInd) => (
                          <a
                            href={obj.link_url[0].text}
                            target="_blank"
                            rel="noreferrer"
                            key={"dfsdf" + appLinkInd}
                          >
                            <img
                              alt="ddd"
                              src={obj.link_icon.url}
                              className="img-fluid"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-lg-1 order-sm-0">
                  <img src={trading_icon.url} alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-8 wideChoices marTop40">
              <h4>{wide_choice[0] !== undefined && wide_choice[0].text}</h4>
              <p>{wide_range_description[0] !== undefined && wide_range_description[0].text}</p>
            </div>
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
              <div className="col-md-1"></div>
                <div className="col-lg-6">
                <video width="100%" autoplay="true" loop="true" muted>
                  <source src={SERVICE_VIDEO} type="video/mp4" />
                  <img
                    src={wide_range_thumbnail.url}
                    alt=""
                    className="img-fluid"
                  /> 
                  </video>
                </div>
                <div className="col-lg-5">
                  <div className="content-desc">
                    <div className="marTop40">
                      <ul className="">
                        {wide_range_features.map((feature, index) => (
                          <li
                            key={"dddf" + index}
                            className="wideChoicesList d-flex"
                          >
                            <div className="icon">
                              <Link to={feature.range_link[0].text}>
                                <div
                                  className="iconCard"
                                  onMouseEnter={() =>
                                    setFeaturesIcons(
                                      featuresIcons.map((icon, i) =>
                                        index === i
                                          ? feature.icon_front.url
                                          : icon
                                      )
                                    )
                                  }
                                  onMouseLeave={() =>
                                    setFeaturesIcons(
                                      featuresIcons.map((icon, i) =>
                                        index === i ? feature.icon.url : icon
                                      )
                                    )
                                  }
                                >
                                  <img
                                    src={featuresIcons[index]}
                                    alt="Card Back"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="contents">
                              <h6 className="m-bottom-10">
                                {feature.range_title[0].text}
                              </h6>
                              <p>{feature.range_feature_description[0].text}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row align-items-center tradingNextLevel">
                <div className="col-lg-5 order-lg-0 order-sm-1">
                  <div className="content-desc">
                    <h4>{next_level[0] !== undefined && next_level[0].text}</h4>
                    <p>{next_level_description[0] !== undefined && next_level_description[0].text}</p>
                    <a
                      href="https://my.accuindex.eu/registerlive"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={e =>
                        setNextLevelImage(next_level_button_clicked_image.url)
                      }
                      onMouseLeave={e =>
                        setNextLevelImage(next_level_button_image.url)
                      }
                    >
                      <div className="card123">
                        <img src={nextLevelImage} alt="Card Back" />
                      </div>
                    </a>
                    <p>{next_level_risk[0] !== undefined && next_level_risk[0].text}</p>

                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 order-lg-1 order-sm-0 margin-md-60">
                  <img
                    src={next_level_thumbnail.url}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
